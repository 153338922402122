var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.offices.length !== 0)?_c('div',{staticClass:"office-container"},[_c('div',{staticClass:"forms"},_vm._l((_vm.offices),function(office,index){return _c('div',{key:'offices' + index,staticClass:"one-with-plus"},[_c('div',{staticClass:"fields"},[_c('div',{staticClass:"field",class:{
            incorrect:
              !_vm.isDataFilled &&
              _vm.wantToSubmit &&
              office.name.toString().trim() === '',
          }},[_c('label',{staticClass:"field-input",attrs:{"for":'name' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.office-name"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.offices.length === 1 && _vm.isEmpty(_vm.newOffices[0]) ? "" : "*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.name),expression:"office.name"}],attrs:{"id":'name' + index,"type":"text"},domProps:{"value":(office.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "name", $event.target.value)}}})])]),_c('div',{staticClass:"two-in-row"},[_c('div',{staticClass:"two-in-row"},[_c('v-select',{staticClass:"info-filling-select",class:{
                incorrect:
                  !_vm.isDataFilled && _vm.wantToSubmit && office.country === '',
              },attrs:{"options":_vm.countries,"label":"name","reduce":function (country) { return country.code; },"placeholder":_vm.$t('information-filling.country'),"transition":".1s","clearable":false,"searchable":false},model:{value:(office.country),callback:function ($$v) {_vm.$set(office, "country", $$v)},expression:"office.country"}}),_c('label',{staticClass:"field postal",class:{
                incorrect:
                  !_vm.isDataFilled &&
                  _vm.wantToSubmit &&
                  office.postal_code.toString().trim() === '',
              },attrs:{"id":'postal' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.postal"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.offices.length === 1 && _vm.isEmpty(_vm.newOffices[0]) ? "" : "*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.postal_code),expression:"office.postal_code"}],attrs:{"id":'postal' + index,"type":"text"},domProps:{"value":(office.postal_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "postal_code", $event.target.value)}}})])],1),_c('label',{staticClass:"field",class:{
              incorrect:
                !_vm.isDataFilled &&
                _vm.wantToSubmit &&
                office.city.toString().trim() === '',
            },attrs:{"for":'city' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.city"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.offices.length === 1 && _vm.isEmpty(_vm.newOffices[0]) ? "" : "*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.city),expression:"office.city"}],attrs:{"id":'city' + index,"type":"text"},domProps:{"value":(office.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "city", $event.target.value)}}})])]),_c('div',{staticClass:"two-in-row"},[_c('label',{staticClass:"field",class:{
              incorrect:
                !_vm.isDataFilled &&
                _vm.wantToSubmit &&
                office.street.toString().trim() === '',
            },attrs:{"for":'street' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.street"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.offices.length === 1 && _vm.isEmpty(_vm.newOffices[0]) ? "" : "*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.street),expression:"office.street"}],attrs:{"id":'street' + index,"type":"text"},domProps:{"value":(office.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "street", $event.target.value)}}})]),_c('div',{staticClass:"two-in-row"},[_c('label',{staticClass:"field",class:{
                incorrect:
                  !_vm.isDataFilled &&
                  _vm.wantToSubmit &&
                  office.building.toString().trim() === '',
              },attrs:{"for":'building' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.streetNumber"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.offices.length === 1 && _vm.isEmpty(_vm.newOffices[0]) ? "" : "*"))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.building),expression:"office.building"}],attrs:{"id":'building' + index,"type":"text"},domProps:{"value":(office.building)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "building", $event.target.value)}}})]),_c('label',{staticClass:"field",class:{
                incorrect:
                  !_vm.isDataFilled &&
                  _vm.wantToSubmit &&
                  office.apartment.toString().trim() === '',
              },attrs:{"for":'flat' + index}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("information-filling.flat"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(office.apartment),expression:"office.apartment"}],attrs:{"id":'flat' + index,"type":"text"},domProps:{"value":(office.apartment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(office, "apartment", $event.target.value)}}})])])])]),_c('div',{staticClass:"buttons-icons"},[_c('i',{staticClass:"fas fa-trash",on:{"click":function($event){return _vm.removeOffice(index)}}},[_c('div',{staticClass:"info"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("information-filling.office-delete")))]),_c('div',{staticClass:"square"})])])]),(index === _vm.offices.length - 1)?_c('i',{staticClass:"fas fa-plus",on:{"click":function($event){return _vm.addOffice()}}},[_c('div',{staticClass:"info"},[_c('h4',[_vm._v(_vm._s(_vm.$t("information-filling.office-add")))]),_c('div',{staticClass:"square"})])]):_vm._e()])])}),0),(_vm.firstTime)?_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button",attrs:{"to":"/dashboard/myprofile/background"}},[_vm._v(_vm._s(_vm.$t("information-filling.back")))]),_c('router-link',{staticClass:"button",attrs:{"to":"/dashboard/myprofile/availability"}},[_vm._v(_vm._s(_vm.$t("information-filling.continue")))])],1):_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button",on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("information-filling.cancel"))+" ")]),_c('button',{staticClass:"button",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("information-filling.save"))+" ")])])]):_c('div',{staticClass:"box"},[_c('spinner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }