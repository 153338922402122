
import { Component, Prop } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import { Route } from "vue-router";
import vSelect from "vue-select";
import "@/scss/vue-select.css";
import { Therapist } from "@/interfaces";
import { get, post, del, patch } from "@/api";
import { TranslateResult } from "vue-i18n";
import i18n from "@/i18n";
const pageKey = "office";

Component.registerHooks(["beforeRouteLeave"]);

@Component({
  components: {
    vSelect,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class InfoOfficeComponent extends Vue {
  therapistOffices: Therapist.TherapistOffice[] = [];
  offices: Therapist.TherapistOffice[] = [];
  wantToSubmit = false;
  countries: Record<string, string | TranslateResult>[] = [];

  @Prop()
  firstTime!: boolean;

  created() {
    this.getOffices();
    this.getCountries();
  }

  async getCountries() {
    try {
      const { data: dictionaries } = await get.dict();
      if (dictionaries)
        [...dictionaries.countries].forEach((lang) =>
          this.countries.push({
            name: this.$t("countries." + lang),
            code: lang,
          })
        );
    } catch (err) {
      console.log(err);
    }
  }

  async getOffices() {
    try {
      const { data } = await get.therapistOffices();
      this.therapistOffices = data;
      if (this.therapistOffices && this.therapistOffices.length !== 0) {
        this.offices = JSON.parse(JSON.stringify(this.therapistOffices));
      } else {
        this.offices = [
          {
            id: 1,
            name: "",
            street: "",
            building: "",
            apartment: "",
            postal_code: "",
            country: "",
            city: "",
          },
        ];
      }
    } catch (err) {
      console.log(err);
    }
  }

  get isSomethingChanged() {
    return (
      this.deletedOffices.length !== 0 ||
      this.changedOffices.length !== 0 ||
      this.newOffices.length > 1 ||
      !(
        (this.newOffices.length === 1 && this.isEmpty(this.newOffices[0])) ||
        JSON.stringify(this.therapistOffices.sort()) ===
          JSON.stringify(this.offices.sort())
      )
    );
  }

  isRequired(index: number) {
    let isRequired = true;
    if (this.offices.length === 1) {
      Object.keys(this.offices[index]).forEach((e: string) => {
        if (this.offices[index][e].toString().trim() !== "" && e !== "id")
          isRequired = true;
      });
      return false || isRequired;
    }
    return isRequired;
  }

  get isDataFilled() {
    if (this.offices.length === 1 && this.isEmpty(this.newOffices[0])) {
      return true;
    } else {
      for (let i = 0; i < this.offices.length; i++) {
        if (this.isRequired(i)) {
          let isFilled = true;
          Object.keys(this.offices[i]).forEach((e: string) => {
            if (this.offices[i][e].toString().trim() === ""  && e!== "apartment" ) isFilled = false;
          });
          if (!isFilled) return false;
        }
      }
    }
    return true;
  }

  get trimmedOffices() {
    const keys = Object.keys(this.offices[0]);
    const trimmedOffices: Therapist.TherapistOffice[] = [];
    for (const office of this.offices) {
      trimmedOffices.push(JSON.parse(JSON.stringify(office)));
    }
    keys.splice(0, 1);
    trimmedOffices.forEach((office) => {
      for (const key of keys) {
        office[key] = office[key].toString().trim();
      }
    });
    return trimmedOffices;
  }

  get newOffices() {
    const oldOfficesIds = this.therapistOffices.map((office) => office.id);
    const newOffices = this.trimmedOffices.filter(
      (office) => oldOfficesIds.indexOf(office.id) === -1
    );
    return newOffices;
  }

  get deletedOffices() {
    const newOfficesIds = this.trimmedOffices.map((office) => office.id);
    const deletedOffices = this.therapistOffices.filter(
      (office) => newOfficesIds.indexOf(office.id) === -1
    );
    return deletedOffices;
  }

  get changedOffices() {
    const keys = Object.keys(this.offices[0]);
    keys.splice(0, 1);
    const oldOfficesIds = this.therapistOffices.map((office) => office.id);
    const changedOffices = this.trimmedOffices.filter((office) => {
      let changed = false;
      if (oldOfficesIds.indexOf(office.id) !== -1) {
        const oldOffice = this.therapistOffices[
          oldOfficesIds.indexOf(office.id)
        ];
        keys.forEach((key) => {
          if (office[key] !== oldOffice[key]) changed = true;
        });
      }
      return changed;
    });
    return changedOffices;
  }

  isEmpty(office: Therapist.TherapistOffice) {
    let isEmpty = true;
    const keys = Object.keys(this.offices[0]);
    if (office) {
      keys.splice(0, 1);
      keys.forEach((key) => {
        if (office[key].toString().trim() !== "") isEmpty = false;
      });
    } else {
      isEmpty = false;
    }
    return isEmpty;
  }

  addOffice() {
    this.offices.push({
      id: this.offices[this.offices.length - 1].id + 1,
      name: "",
      street: "",
      apartment: "",
      building: "",
      postal_code: "",
      country: "",
      city: "",
    });
  }

  removeOffice(index: number) {
    if (this.offices.length !== 1) {
      this.offices.splice(index, 1);
    } else {
      this.offices.splice(index, 1, {
        id: 1,
        name: "",
        street: "",
        building: "",
        apartment: "",
        postal_code: "",
        country: "",
        city: "",
      });
    }
  }

  cancel() {
    if (this.therapistOffices && this.therapistOffices.length !== 0) {
      this.offices = JSON.parse(JSON.stringify(this.therapistOffices));
    } else {
      this.offices = [
        {
          id: 1,
          name: "",
          street: "",
          building: "",
          apartment: "",
          postal_code: "",
          country: "",
          city: "",
        },
      ];
    }
  }

  async save() {
    if (this.isDataFilled) {
      await this.submit();
    } else {
      this.wantToSubmit = true;
    }
  }

  async submit() {
    if (this.newOffices.length !== 0) {
      if (!(this.newOffices.length === 1 && this.isEmpty(this.newOffices[0]))) {
        for (let i = 0; i < this.newOffices.length; i++) {
          try {
            await post.therapistOffice(this.newOffices[i]);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
    if (this.deletedOffices.length !== 0) {
      for (let i = 0; i < this.deletedOffices.length; i++) {
        try {
          await del.therapistOffice(this.deletedOffices[i].id);
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (this.changedOffices.length !== 0) {
      for (let i = 0; i < this.changedOffices.length; i++) {
        try {
          await patch.therapistOffice(
            this.changedOffices[i].id,
            this.changedOffices[i]
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
    this.getOffices();

    try {
      let therapist: Therapist.TherapistInfo;
      if (this.offices.length === 1 && this.isEmpty(this.newOffices[0])) {
        const { data } = await patch.therapistInfo({
          skip_office: true,
        });
        therapist = data;
      } else {
        const { data } = await patch.therapistInfo({
          skip_office: false,
        });
        therapist = data;
      }
      this.$store.commit("setStage", therapist.completed_fillings);
    } catch (err) {
      console.log(err);
    }
    if (!this.firstTime) this.$openModal({ component: "SavedSuccessfullyModal" });
  }

  async beforeRouteLeave(to: Route, from: Route, next: any) {
    if (
      this.firstTime &&
      to.meta.requiresAuth &&
      to.path !== "/dashboard/myprofile/background"
    ) {
      if (this.isDataFilled) {
        await this.submit();
        next();
      }
      this.wantToSubmit = true;
    } else if (
      (this.firstTime &&
        !this.isSomethingChanged &&
        to.path === "/dashboard/myprofile/background") ||
      (!this.firstTime && !this.isSomethingChanged) ||
      !this.$store.state.user
    ) {
      next();
    } else {
      this.$openModal({
        component: "InfoFillingModal",
        props: {
          next: () => next(),
          save: () => this.save(),
          cancel: () => this.cancel(),
          isDataFilled: this.isDataFilled
        },
        this: this,
      });
    }
  }
}
